import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { SentryService } from '../../../../core/services/sentry.service';
import { InformService } from '../../../../core/services/inform.service';
import { InvoicesPaymentFormComponent } from '../../components/invoices-payment-form/invoices-payment-form.component';
import { InvoicesService } from '../../services/invoices.service';
import {
  InvoicesPaymentService,
  InvoicePaymentResponse,
} from '../../services/invoices-payment.service';
import { ActivatedRoute } from '@angular/router';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { AmplitudePayloadType, INPUT_PAGE_ENTER } from 'app/core/constants/amplitude-helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  invoiceStatus,
  invoiceStatusClass,
} from 'app/features/attune-bop/models/invoice-list-helpers';
import {
  prettyPaymentPlan,
  HAB_PRODUCT_NAME,
  CYBER_ADMITTED_PRODUCT_NAME,
  CYBER_SURPLUS_PRODUCT_NAME,
  WC_PRODUCT_NAME,
} from '../../models/invoices-constants';
import {
  InvoicesPaginationService,
  PaginationData,
} from '../../services/invoices-pagination.service';
import { InvoicesBannerService } from '../../services/invoices-banner.service';
import { UNAUTHENTICATED_ROUTE } from 'app/features/support/models/support-help-center-constants';
import { US_DATE_SINGLE_DIGIT_MASK } from 'app/constants';
import { FullstoryService } from 'app/core/services/fullstory.service';
import { DocumentService } from '../../../documents/services/document.service';
import {
  getInvoiceReceiptFileName,
  getScheduleOfInvoicesFileName,
} from '../../../documents/models/document-file-names.model';
import {
  getAttuneBopInvoiceReceiptUrl,
  getAttuneBopScheduleOfInvoicesUrl,
} from '../../../documents/models/document-urls.model';
import { datadogRum } from '@datadog/browser-rum';
import { INSURED_BILLING_GUIDE } from 'app/features/support/models/support-constants';

export interface InsuredCurrentPolicyInfo {
  policyNumbers: Record<string, string>;
  periodStart: moment.Moment;
  periodEnd: moment.Moment;
  paymentPlans: { [key: string]: string };
  premiums: { [key: string]: number };
}

interface InvoiceCurrentAndFutureInfo {
  relevantPolicy: InsuredCurrentPolicyInfo;
  futurePolicy?: InsuredCurrentPolicyInfo;
}

export interface InvoiceView {
  status: 'loading' | 'success' | 'error';
  errorMessage?: string;
}

export interface InvoiceLoadedView {
  banners: Record<string, boolean>;
  cancellationDate: string | null;
  status: 'success';
  accountName: string;
  accountNumber: string;
  errorMessage?: string;
  hasAutopayEnabled: boolean;
  amountToPay: number;
  invoiceId: string;
  includesHabPolicy: boolean;
  includesCyberPolicy: boolean;
  includesAttuneWcPolicy: boolean;
  hasAutopayCreditCard: boolean;
  currentAutopayText: string;
  mostRecentInvoice: BackendListInvoice;
  payoffAmount: number;
  relevantPolicy: InsuredCurrentPolicyInfo;
  futurePolicy?: InsuredCurrentPolicyInfo;
  relevantPolicyHasMultipleProducts: boolean;
  relevantPolicyHasMultiplePremiums: boolean;
  relevantPolicyHasMultiplePaymentPlans: boolean;
  showPremium: boolean;
  nextBillDate: string;
  nextDueDate: string;
  nextBillAmount: number | null;
  overdueAmount: number | null;
  isCardProcessingFeeEligible: boolean;
  billingStatus: 'next' | 'current' | 'overdue';
  isCancelled: boolean;
  remainingAnnualPremium: number | null;
  pendingReinstatementInvoice: BackendListInvoice;
}

function isLoadedView(view: InvoiceView | InvoiceLoadedView): view is InvoiceLoadedView {
  return (view as InvoiceLoadedView).mostRecentInvoice !== undefined;
}

@Component({
  selector: 'app-invoices-insured-page.app-page.app-page__invoice-list',
  templateUrl: './invoices-insured-page.component.html',
})
export class InvoicesInsuredPageComponent implements OnInit {
  invoiceView$ = new BehaviorSubject<InvoiceView | InvoiceLoadedView>({ status: 'loading' });
  billedInvoices: BackendListInvoice[];
  plannedInvoices: BackendListInvoice[];

  // NOTE (olex): Please don't un-private / use in template! Can be moved to service.
  private invoices: BackendListInvoice[];
  private accountSummary: AccountSummary;
  private invoiceDetails: BackendInvoiceDetails | null;
  private autopayDetails: AutopayInfoResponse | null;
  private policies: BackendInvoiceAssociatedPolicy[];

  @ViewChild(InvoicesPaymentFormComponent)
  paymentFormComponent: InvoicesPaymentFormComponent;

  currentYear = new Date().getFullYear();

  // Variables to track UI state
  showAutopayEnrollModal = false;
  showMakePaymentModal = false;
  showUpdatePaymentModal = false;
  showPayoffModal = false;
  showPaymentPlanModal = false;

  paymentUpdateSuccessful = false;
  isModalProcessing = false;
  madeCardPayment = false;
  madeAchPayment = false;
  madeEarlyPayoff = false;
  serverError = '';
  pager: PaginationData;
  pagedBilledItems: BackendListInvoice[];
  pagedPlannedItems: BackendListInvoice[];
  billingInvoicePager: PaginationData;
  pdfData: void;
  isLoadingScheduleOfInvoices = false;
  isLoadingInsuredGuide = false;
  insuredBillingGuide = INSURED_BILLING_GUIDE;
  currentId: string | null;
  hideAccountDetail = false;

  // Helpers
  invoiceStatusClass = invoiceStatusClass;
  invoiceStatus = invoiceStatus;
  helpCenterPath = UNAUTHENTICATED_ROUTE;

  dateMask = US_DATE_SINGLE_DIGIT_MASK;

  constructor(
    private amplitudeService: AmplitudeService,
    private sentryService: SentryService,
    private fullstoryService: FullstoryService,
    private informService: InformService,
    private insuredPaginationService: InvoicesPaginationService,
    private invoiceService: InvoicesService,
    private invoiceBannerService: InvoicesBannerService,
    private invoicePaymentService: InvoicesPaymentService,
    private documentService: DocumentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.params;
    const query = this.route.snapshot.queryParams;

    this.amplitudeService.submitEvent({
      input: INPUT_PAGE_ENTER,
      type: AmplitudePayloadType.Page,
      value: `/insured/${params.accountId}`,
    });

    if (!query.invoicePid || !query.invoiceToken) {
      // EXIT / does not attempt fetching invoice info
      this.invoiceView$.next({
        status: 'error',
        errorMessage: 'Cannot get the invoice details. Please double-check the URL.',
      });
      return;
    }

    const source = this.route.snapshot.queryParams.src;
    const smsMessageId = this.route.snapshot.queryParams.msg_id || '';
    if (
      [
        'sms_welcome',
        'sms_delinquency',
        'sms_delinquency_second_reminder',
        'sms_failed_payment',
      ].includes(source)
    ) {
      this.amplitudeService.trackWithOverride({
        eventName: `billing_${source}`,
        detail: `/insured/${params.accountId}`,
        payloadOverride: { smsMessageId },
        useLegacyEventName: true,
      });
    }

    this.invoiceService.getAssociatedAccountSummary(query.invoicePid, query.invoiceToken).subscribe(
      (response) => {
        this.accountSummary = response;
        this.updateCalculatedView();
      },
      (_error) => {
        this.invoiceView$.next({
          status: 'error',
          errorMessage: 'Cannot get account info. Please retry or double-check the URL.',
        });
      }
    );
    this.loadInvoiceInfo();
  }

  formatDate(date: Date) {
    return moment.utc(date).format('MM/DD/YYYY');
  }

  formatDateLong(date: Date) {
    return moment.utc(date).format('dddd, MMMM D, YYYY');
  }

  isBannerShowing() {
    const view = this.invoiceView();
    return (
      isLoadedView(view) &&
      view.banners &&
      !view.pendingReinstatementInvoice &&
      Object.keys(view.banners).some((banner) => view.banners[banner] === true)
    );
  }

  loadInvoiceInfo() {
    const params = this.route.snapshot.params;
    const query = this.route.snapshot.queryParams;

    // Clear out any old, no-longer-relevant information here
    this.invoiceDetails = null;
    this.autopayDetails = null;

    this.invoiceService
      .getAutopayStatus(params.accountId, query.invoicePid, query.invoiceToken)
      .subscribe(
        (response) => {
          this.autopayDetails = response;
          this.updateCalculatedView();
        },
        (_error) => {
          this.invoiceView$.next({
            status: 'error',
            errorMessage: 'Cannot get autopay details. Please retry or double-check the URL.',
          });
        }
      );

    this.invoiceService
      .getAssociatedPolicies(query.invoicePid, query.invoiceToken)
      .subscribe((response: BackendInvoiceAssociatedPolicyResponse) => {
        this.policies = response.associatedPolicies;
        this.updateCalculatedView();
      });

    this.invoiceService.getPlannedInvoices(query.invoicePid, query.invoiceToken).subscribe(
      (response: BackendAssociatedInvoices) => {
        if (response?.authInvoiceDetails?.accountNumber) {
          datadogRum.addAction('View Invoice', {
            accountID: response?.authInvoiceDetails?.accountNumber,
            accountName: response?.authInvoiceDetails?.accountName,
          });
        }

        this.invoices = response.associatedInvoices;
        this.invoiceDetails = response.authInvoiceDetails;
        this.billedInvoices = this.invoices
          .filter((inv: BackendListInvoice) => inv.status !== 'Planned')
          .filter((inv: BackendListInvoice) => {
            // Filter out reinstatement invoices, but only if the associated policy is not pending reinstatement
            return (
              !inv.isReinstatementInvoice ||
              inv.lineItems.some((lineItem) => lineItem.isPendingReinstatement)
            );
          })
          .sort((a, b) => (a.dueDate > b.dueDate ? -1 : 1));
        this.plannedInvoices = this.invoices
          .filter((inv: BackendListInvoice) => inv.status === 'Planned')
          .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1));
        this.setBillingPage(1);
        this.setPlannedPage(1);
        this.updateCalculatedView();
      },
      (_error) => {
        this.invoiceView$.next({
          status: 'error',
          errorMessage: 'Cannot get invoices. Please retry or double-check the URL.',
        });
      }
    );
  }

  openAutopayModal() {
    this.showAutopayEnrollModal = true;
  }

  openMakePaymentModal() {
    this.showMakePaymentModal = true;
  }

  openUpdatePaymentModal() {
    this.showUpdatePaymentModal = true;
  }

  openPayoffModal() {
    this.showPayoffModal = true;
  }

  openPaymentPlanModal() {
    this.showPaymentPlanModal = true;
  }

  updateCalculatedView() {
    if (this.invoiceView().status === 'error') {
      return;
    }
    if (this.invoices && this.accountSummary && this.invoiceDetails) {
      // ALL endpoints returned w/ successes / render list
      const policies = this.calculateCurrentPolicy(this.accountSummary);
      const relevantPolicy = policies.relevantPolicy;
      const hasAutopayCreditCard =
        !!this.autopayDetails &&
        !!this.autopayDetails.autopayEnrolled &&
        !!this.autopayDetails.paymentMethod &&
        this.autopayDetails.paymentMethod.description === 'Credit Card';

      let banners = {};
      let cancellationDate = null;
      if (this.policies) {
        banners = this.calculateBanners(
          this.accountSummary,
          this.invoices,
          this.policies,
          ...this.invoiceBannerService.filterInvoicesAndPoliciesForTopBanner(
            this.billedInvoices,
            this.policies
          )
        );
        cancellationDate = this.calculateCancellationDate(this.policies);
        if (cancellationDate) {
          cancellationDate = moment.utc(cancellationDate).format('MM/DD/YYYY');
        }
      }

      const invoicesRecentFirst = this.invoices.slice().sort((a, b) => {
        return a.billDate > b.billDate ? -1 : 1;
      });
      const pendingReinstatementInvoice = invoicesRecentFirst.find((invoice) => {
        return (
          invoice.isReinstatementInvoice &&
          invoice.lineItems.some((lineItem) => lineItem.isPendingReinstatement)
        );
      });

      const nextInvoices = this.invoices
        .filter((invoice) => invoice.status === 'Planned')
        .sort((a, b) => {
          return a.billDate < b.billDate ? -1 : 1;
        });
      const nextInvoiceDetail = nextInvoices.length ? nextInvoices[0] : null;
      const amountToPay = pendingReinstatementInvoice
        ? pendingReinstatementInvoice.amountDue
        : this.invoiceDetails.outstandingAmount;
      const invoiceId = pendingReinstatementInvoice
        ? pendingReinstatementInvoice.id
        : this.invoiceDetails.id;
      this.invoiceView$.next({
        banners,
        cancellationDate,
        status: 'success',
        futurePolicy: policies.futurePolicy ? policies.futurePolicy : undefined,
        relevantPolicy: relevantPolicy,
        accountName: this.accountSummary.account.accountName,
        accountNumber: this.accountSummary.account.accountNumber,
        hasAutopayEnabled: !!this.autopayDetails && this.autopayDetails.autopayEnrolled,
        amountToPay,
        invoiceId,
        includesHabPolicy: this.includesHabPolicy(this.accountSummary),
        includesCyberPolicy: this.includesCyberPolicy(this.accountSummary),
        includesAttuneWcPolicy: this.includesAttuneWcPolicy(this.accountSummary),
        mostRecentInvoice: this.invoices[0],
        isCardProcessingFeeEligible: this.invoiceDetails.isCardProcessingFeeEligible,
        payoffAmount: this.invoiceDetails.payoffAmount,
        hasAutopayCreditCard,
        currentAutopayText: this.autopayDetails
          ? this.getAutopayPaymentText(this.autopayDetails)
          : '',
        showPremium:
          Object.values(relevantPolicy.premiums).reduce((acc, x) => {
            return acc + x;
          }, 0) > 0,
        relevantPolicyHasMultipleProducts: Object.keys(relevantPolicy.paymentPlans).length > 1,
        relevantPolicyHasMultiplePremiums: Object.keys(relevantPolicy.premiums).length > 1,
        relevantPolicyHasMultiplePaymentPlans:
          Object.keys(relevantPolicy.paymentPlans).length > 1
            ? !Object.values(relevantPolicy.paymentPlans).every(
                (plan) => plan === Object.values(relevantPolicy.paymentPlans)[0]
              )
            : false,
        nextBillDate: this.getNextBillDate(nextInvoiceDetail),
        nextDueDate: this.getNextDueDate(nextInvoiceDetail),
        nextBillAmount: this.getNextBillAmount(nextInvoiceDetail),
        overdueAmount: this.getOverdueAmount(this.invoices),
        billingStatus: this.calculateStatus(
          this.invoiceDetails.outstandingAmount,
          this.getOverdueAmount(this.invoices)
        ),
        isCancelled: this.policies
          ? this.isCancelled(
              ...this.invoiceBannerService.filterInvoicesAndPoliciesForTopBanner(
                this.billedInvoices,
                this.policies
              )
            )
          : false,
        remainingAnnualPremium: this.getRemainingAnnualPremium(),
        pendingReinstatementInvoice,
      });
    }
  }

  getAutopayPaymentText(autopayInfo: AutopayInfoResponse) {
    if (autopayInfo.paymentMethod && autopayInfo.paymentMethod.description === 'Credit Card') {
      const last4Digits = autopayInfo.displayName ? autopayInfo.displayName.slice(-4) : '****';
      if (last4Digits.length > 0) {
        return `Card ending in ${last4Digits}`;
      }
      return '';
    } else if (autopayInfo.paymentMethod && autopayInfo.paymentMethod.description === 'ACH/EFT') {
      // ACH numbers come back from the API in the format "ACH/EFT (******6789/110000000)"
      // This regex selects the section "******6789", which is what we want to display.
      const bankNumberRegex = /(\*+\d*)/;
      const regexResults = bankNumberRegex.exec(autopayInfo.displayName || '');
      const bankNumber = regexResults && regexResults[0];
      if (bankNumber && bankNumber.length > 0) {
        return `ACH Transfer (${bankNumber})`;
      }
      return '';
    } else {
      return 'Not available';
    }
  }

  getFilteredPolicies(associatedPolicies: BackendInvoiceAssociatedPolicy[]) {
    return this.invoiceBannerService.calculatefilterPolicies(
      associatedPolicies,
      this.accountSummary
    );
  }

  calculateCancellationDate(associatedPolicies: BackendInvoiceAssociatedPolicy[]) {
    const policyStatus = this.invoiceBannerService.getPolicyStatus(associatedPolicies);
    const filteredPolicies = this.getFilteredPolicies(associatedPolicies);
    if (['Pending Cancel', 'Canceled'].includes(policyStatus)) {
      return this.invoiceBannerService.getCancellationDate(filteredPolicies);
    }
    return null;
  }

  calculateBanners(
    accountSummary: AccountSummary,
    allInvoices: BackendListInvoice[],
    allPolicies: BackendInvoiceAssociatedPolicy[],
    unpaidInvoices: BackendListInvoice[],
    associatedPolicies: BackendInvoiceAssociatedPolicy[]
  ) {
    const policyStatus = this.invoiceBannerService.getPolicyStatus(associatedPolicies);
    const hasOutstandingInvoices = unpaidInvoices.length > 0;
    const hasMultipleOutstanding = unpaidInvoices.length > 1;
    const isNonpayCancellation = this.invoiceBannerService.isNonPayCancellation(associatedPolicies);
    const isUWCancellation = this.invoiceBannerService.isUWCancellation(associatedPolicies);
    const hasPastDueInvoices =
      this.invoiceBannerService.getNumOfPastDueInvoices(unpaidInvoices) > 0;
    const earliestInvoiceDueDate = moment.min(
      unpaidInvoices.map((invoice) => moment.utc(invoice.dueDate))
    );
    const isAutopayEnabled = !!this.autopayDetails && this.autopayDetails.autopayEnrolled;
    const renewalPolicyNumber = this.invoiceBannerService.getRenewalPolicyNumber(allPolicies);
    const firstRenewalInvoice = renewalPolicyNumber
      ? this.invoiceBannerService.getFirstInvoiceForPolicy(allInvoices, renewalPolicyNumber)
      : null;
    const renewalDueDate = firstRenewalInvoice ? firstRenewalInvoice.dueDate : null;
    const renewalDraftDate = renewalDueDate ? this.getDraftDate(renewalDueDate) : null;
    const now = moment();
    const showRenewalOverdue =
      renewalDueDate &&
      firstRenewalInvoice &&
      firstRenewalInvoice.status !== 'Paid' &&
      policyStatus !== 'Canceled'
        ? moment(now).isAfter(moment(renewalDueDate))
        : false;
    const showRenewalBilled =
      !showRenewalOverdue &&
      firstRenewalInvoice &&
      firstRenewalInvoice.status !== 'Planned' &&
      firstRenewalInvoice.status !== 'Paid' &&
      policyStatus !== 'Canceled';
    const showRenewalBanner = showRenewalBilled || showRenewalOverdue;
    const showEarlyPayoff =
      this.invoiceDetails &&
      this.invoiceDetails.payoffAmount &&
      policyStatus !== 'Pending Cancel' &&
      policyStatus !== 'Canceled';

    const showPriorityTopBanner =
      showRenewalOverdue ||
      hasPastDueInvoices ||
      policyStatus === 'Canceled' ||
      policyStatus === 'Pending Cancel';

    const hasNyPolicy = allPolicies.some((policy) => policy.BaseState === 'NY');
    const gracePeriodDays = hasNyPolicy ? 10 : 5;
    const gracePeriodAfterEarliestDueDate = earliestInvoiceDueDate.add(gracePeriodDays, 'days');
    return {
      overdueInvoice: policyStatus === 'Active' && hasPastDueInvoices,
      nonpayCancelWithOutstanding:
        policyStatus === 'Canceled' &&
        isNonpayCancellation &&
        !isUWCancellation &&
        hasPastDueInvoices,
      nonpayCancelWithoutOutstanding:
        policyStatus === 'Canceled' &&
        isNonpayCancellation &&
        !isUWCancellation &&
        !hasPastDueInvoices,
      nonpayPendingCancellation: policyStatus === 'Pending Cancel' && isNonpayCancellation,

      outstandingWithoutDelinquencies:
        policyStatus === 'Active' && !hasPastDueInvoices && hasMultipleOutstanding,

      uwCancelWithOutstanding:
        policyStatus === 'Canceled' &&
        isUWCancellation &&
        !isNonpayCancellation &&
        hasOutstandingInvoices,
      uwCancelWithoutOutstanding:
        policyStatus === 'Canceled' &&
        isUWCancellation &&
        !isNonpayCancellation &&
        !hasOutstandingInvoices,
      uwPendingCancel: policyStatus === 'Pending Cancel' && isUWCancellation,
      otherCancelReasons:
        (policyStatus === 'Pending Cancel' && !isNonpayCancellation && !isUWCancellation) ||
        (policyStatus === 'Canceled' && !isNonpayCancellation && !isUWCancellation),
      earliestInvoiceDueDate,
      gracePeriodAfterEarliestDueDate,
      autopayPastDueInvoices:
        (policyStatus === 'Active' && hasPastDueInvoices) ||
        (policyStatus === 'Pending Cancel' && isNonpayCancellation && hasPastDueInvoices) ||
        (policyStatus === 'Pending Cancel' &&
          !isNonpayCancellation &&
          !hasOutstandingInvoices &&
          hasPastDueInvoices),
      renewalDueDate,
      renewalDraftDate,
      showEarlyPayoff,
      showPriorityTopBanner,
      showRenewalBilled,
      showRenewalOverdue,
      showRenewalBanner,
    };
  }

  calculateStatus(
    amountToPay: number,
    overdueAmount: number | null
  ): 'next' | 'current' | 'overdue' {
    if (amountToPay === 0) {
      return 'next';
    }
    if (overdueAmount) {
      return 'overdue';
    }
    return 'current';
  }

  includesHabPolicy(accountSummary: AccountSummary) {
    return accountSummary.account.policyPeriods.some(
      (period) => period.lineOfBusinessCode === HAB_PRODUCT_NAME
    );
  }

  includesCyberPolicy(accountSummary: AccountSummary) {
    return accountSummary.account.policyPeriods.some(
      (period) =>
        period.lineOfBusinessCode === CYBER_ADMITTED_PRODUCT_NAME ||
        period.lineOfBusinessCode === CYBER_SURPLUS_PRODUCT_NAME
    );
  }

  includesAttuneWcPolicy(accountSummary: AccountSummary) {
    return accountSummary.account.policyPeriods.some(
      (period) => period.lineOfBusinessCode === WC_PRODUCT_NAME
    );
  }

  getNextBillDate(nextInvoiceDetails: BackendListInvoice | null) {
    if (nextInvoiceDetails) {
      return moment.utc(nextInvoiceDetails.billDate).format('MM/DD/YYYY');
    }
    return 'N/A';
  }

  getNextDueDate(nextInvoiceDetails: BackendListInvoice | null) {
    if (nextInvoiceDetails) {
      return moment.utc(nextInvoiceDetails.dueDate).format('MM/DD/YYYY');
    }
    return 'N/A';
  }

  getNextBillAmount(nextInvoiceDetails: BackendListInvoice | null) {
    const view = this.invoiceView();
    if (isLoadedView(view) && this.hasPendingReinstatement()) {
      const reinstatementInvoice = view.pendingReinstatementInvoice;
      return reinstatementInvoice.amountDue;
    }
    if (nextInvoiceDetails) {
      return nextInvoiceDetails.amountDue;
    }
    return null;
  }

  getOverdueAmount(invoices: BackendListInvoice[]): number | null {
    const view = this.invoiceView();
    if (!isLoadedView(view)) {
      return 0;
    }
    if (this.hasPendingReinstatement()) {
      const reinstatement = view.pendingReinstatementInvoice;
      return reinstatement.status === 'Due' ? reinstatement.amountDue : 0;
    }

    let totalDue = 0;
    invoices.forEach((invoice) => {
      if (invoice.status === 'Due') {
        totalDue += invoice.amountDue;
      }
    });
    return totalDue ? totalDue : null;
  }

  getImminentPaymentDue() {
    const view = this.invoiceView();
    if (isLoadedView(view) && this.hasPendingReinstatement()) {
      return false;
    }

    if (!this.invoiceDetails) {
      return false;
    }
    const dueDate = moment.utc(this.invoiceDetails.dueDate);
    const isAlmostDue = moment.utc().isAfter(dueDate.subtract(3, 'day'));
    return isAlmostDue && this.invoiceDetails.outstandingAmount > 0;
  }

  getDraftDate(date: string) {
    return moment.utc(date).subtract(3, 'day').format();
  }

  getInvoiceUrlSegments(invoice: BackendListInvoice): string[] {
    return ['/bop', 'invoice', invoice.id];
  }

  invoiceView(): InvoiceView | InvoiceLoadedView {
    return this.invoiceView$.value;
  }

  showStripePaymentModal() {
    return (
      this.showAutopayEnrollModal ||
      this.showMakePaymentModal ||
      this.showUpdatePaymentModal ||
      this.showPayoffModal
    );
  }

  isShowingPaymentPlanModal() {
    return this.showPaymentPlanModal;
  }

  getAmountToPay() {
    const view = this.invoiceView();
    if (!isLoadedView(view)) {
      return 0;
    } else if (view.pendingReinstatementInvoice) {
      return view.pendingReinstatementInvoice.amountDue;
    }

    if (this.showPayoffModal) {
      return view.payoffAmount;
    }
    return view.amountToPay;
  }

  getCreditCardFee() {
    if (this.getAmountToPay() === 0 || !this.invoiceDetails) {
      return null;
    }
    if (this.showPayoffModal) {
      return this.invoiceDetails.creditCardFeePayoffAmount;
    }
    return this.invoiceDetails.creditCardFeeOutstandingAmount;
  }

  getCreditCardFeePercentage() {
    if (!this.invoiceDetails) {
      return null;
    }
    // TODO: Update service billing to return creditCardPercent in all endpoints. GW calls the field creditCardPctOrDollar in case the credit card fee is a flat dollar amount
    // rather than a percent of the premium, but since flat credit card fees aren't a thing for any states yet and likely won't be, for now we assume everything's a percent
    return this.invoiceDetails.creditCardPercent || this.invoiceDetails.creditCardPctOrDollar;
  }

  closeStripePaymentModal() {
    this.showAutopayEnrollModal = false;
    this.showMakePaymentModal = false;
    this.showUpdatePaymentModal = false;
    this.showPayoffModal = false;
    this.showPaymentPlanModal = false;
    this.paymentFormComponent.clearForm();
  }

  closePaymentPlanModalAndReload() {
    const params = this.route.snapshot.params;
    const query = this.route.snapshot.queryParams;

    this.closeStripePaymentModal();

    this.invoiceService.getAssociatedAccountSummary(query.invoicePid, query.invoiceToken).subscribe(
      (response) => {
        this.accountSummary = response;
        this.updateCalculatedView();
      },
      (_error) => {
        this.invoiceView$.next({
          status: 'error',
          errorMessage: 'Cannot get account info. Please retry or double-check the URL.',
        });
      }
    );
    this.loadInvoiceInfo();
  }

  placeholderRange() {
    return _.range(0, 5);
  }

  handlePaymentFormSubmit() {
    const invoiceView = this.invoiceView();
    if (!isLoadedView(invoiceView)) {
      this.sentryService.notify(
        'Tried to submit payment form on invoice list, but payment information was not loaded'
      );
      return;
    }
    this.isModalProcessing = true;
    if (this.showAutopayEnrollModal) {
      if (invoiceView.amountToPay) {
        this.submitPayment(true, false);
      } else {
        this.submitEnrollAutopay();
      }
    } else if (this.showMakePaymentModal) {
      this.submitPayment(this.paymentFormComponent.getAutopayStatus(), false);
    } else if (this.showPayoffModal) {
      this.submitPayment(this.paymentFormComponent.getAutopayStatus(), true);
    } else if (this.showUpdatePaymentModal) {
      this.submitUpdatePaymentMethod();
    } else {
      this.sentryService.notify(
        'Tried to submit payment form on invoice list, but no payment modal was open'
      );
    }
  }

  submitEnrollAutopay() {
    const params = this.route.snapshot.params;
    const accountNumber = params.accountId;

    let enroll$: Observable<InvoicePaymentResponse>;
    if (this.paymentFormComponent.isACHPayment()) {
      enroll$ = this.invoicePaymentService.enrollAutopayWithAch(
        this.paymentFormComponent.generateBankData(),
        accountNumber
      );
    } else {
      enroll$ = this.invoicePaymentService.enrollAutopayWithCard(
        this.paymentFormComponent.getCardNumber(),
        accountNumber
      );
    }

    enroll$.subscribe(
      () => {
        const paymentType = this.paymentFormComponent.isACHPayment() ? 'ach' : 'card';
        this.amplitudeService.track({
          eventName: `enroll_autopay_${paymentType}`,
          detail: 'insured_invoice_list',
          useLegacyEventName: true,
        });
        this.isModalProcessing = false;
        this.closeStripePaymentModal();
        this.loadInvoiceInfo();
      },
      (err) => this.handleServerErrors(err)
    );
  }

  submitPayment(isRecurring: boolean, isPayoff: boolean) {
    const invoiceView = this.invoiceView();
    if (!isLoadedView(invoiceView) || !this.invoiceDetails) {
      this.sentryService.notify(
        'Tried to make payment on invoice list, but payment information was not loaded'
      );
      return;
    }
    const params = this.route.snapshot.params;
    const accountNumber = params.accountId;

    let amount = invoiceView.amountToPay;
    if (isPayoff) {
      amount = invoiceView.payoffAmount;
    }

    // Use the ID of the oldest invoice with outstanding charges.
    // If no such invoice exists, resort to the ID of the invoice used to auth the page.
    let oldestInvoiceWithOutstandingCharges;
    if (this.invoices && this.invoices.length) {
      const invoicesOldestToNewest = [...this.invoices].sort((invoice1, invoice2) => {
        return moment.utc(invoice1.dueDate).isBefore(moment.utc(invoice2.dueDate)) ? -1 : 1;
      });
      oldestInvoiceWithOutstandingCharges = invoicesOldestToNewest.find(
        (invoice) => invoice.amountDue > 0
      );
    }

    let invoiceNumber = oldestInvoiceWithOutstandingCharges
      ? oldestInvoiceWithOutstandingCharges.invoiceNumber
      : this.invoiceDetails.invoiceNumber;
    if (invoiceView.pendingReinstatementInvoice) {
      invoiceNumber = invoiceView.pendingReinstatementInvoice.invoiceNumber;
    }

    const invoiceToken = this.invoiceDetails.token;
    const invoiceId = invoiceView.invoiceId;

    let makePayment$: Observable<InvoicePaymentResponse>;
    if (this.paymentFormComponent.isACHPayment()) {
      makePayment$ = this.invoicePaymentService.processBankPayment(
        this.paymentFormComponent.generateBankData(),
        isRecurring,
        accountNumber,
        invoiceNumber,
        amount,
        invoiceToken,
        invoiceId
      );
    } else {
      makePayment$ = this.invoicePaymentService.processCreditCardPayment(
        this.paymentFormComponent.getCardNumber(),
        {},
        isRecurring,
        accountNumber,
        invoiceNumber,
        amount,
        invoiceToken,
        invoiceId
      );
    }

    makePayment$.subscribe(
      () => {
        const paymentType = this.paymentFormComponent.isACHPayment() ? 'ach' : 'card';
        if (isRecurring) {
          this.amplitudeService.track({
            eventName: `enroll_autopay_${paymentType}`,
            detail: 'insured_invoice_list',
            useLegacyEventName: true,
          });

          this.amplitudeService.track({
            eventName: `enroll_autopay_with_payment_${paymentType}`,
            detail: 'insured_invoice_list',
            useLegacyEventName: true,
          });
        } else {
          this.amplitudeService.track({
            eventName: `make_payment_${paymentType}`,
            detail: 'insured_invoice_list',
            useLegacyEventName: true,
          });
        }
        this.isModalProcessing = false;
        if (this.showPayoffModal) {
          this.madeEarlyPayoff = true;
        }
        this.closeStripePaymentModal();
        this.loadInvoiceInfo();
        if (paymentType === 'ach') {
          this.madeAchPayment = true;
        } else {
          this.madeCardPayment = true;
        }
      },
      (err) => this.handleServerErrors(err)
    );
  }

  submitUpdatePaymentMethod() {
    const invoiceView = this.invoiceView();
    if (!isLoadedView(invoiceView)) {
      this.sentryService.notify(
        'Tried to update payment info on invoice list, but payment information was not loaded'
      );
      return;
    }
    const params = this.route.snapshot.params;
    const accountNumber = params.accountId;

    let makePayment$: Observable<InvoicePaymentResponse>;
    if (this.paymentFormComponent.isACHPayment()) {
      makePayment$ = this.invoicePaymentService.sendBankUpdate(
        this.paymentFormComponent.generateBankData(),
        accountNumber
      );
    } else {
      makePayment$ = this.invoicePaymentService.sendCardUpdate(
        this.paymentFormComponent.getCardNumber(),
        accountNumber
      );
    }

    makePayment$.subscribe(
      () => {
        const paymentType = this.paymentFormComponent.isACHPayment() ? 'ach' : 'card';
        this.amplitudeService.track({
          eventName: `update_payment_${paymentType}`,
          detail: 'insured_invoice_list',
          useLegacyEventName: true,
        });
        this.isModalProcessing = false;
        this.closeStripePaymentModal();
        this.loadInvoiceInfo();
      },
      (err) => this.handleServerErrors(err)
    );
  }

  handleServerErrors(serverError: any) {
    this.isModalProcessing = false;
    // set error messages and then reopen first modal
    if (serverError.code && serverError.message) {
      this.serverError = serverError.message;
    } else if (serverError.error && serverError.error.errors) {
      this.serverError = serverError.error.errors[0];
    } else if (
      ['card_error', 'validation_error', 'invalid_request_error'].includes(serverError.type)
    ) {
      // Stripe errors with messages that are friendly enough to show to users
      // See https://stripe.com/docs/api/errors
      this.serverError = serverError.message;
    } else {
      this.sentryService.notify('Invoice payment received an error in an unexpected format', {
        severity: 'error',
        metaData: {
          errorData: JSON.stringify(serverError),
          invoiceNumber: this.route.snapshot.params.id,
        },
      });
      this.serverError =
        'Oops, seems like there is an issue. If this problem persists, please try again later or call 888-530-4650';
    }
  }

  private calculateCurrentPolicy(accountSummary: AccountSummary): InvoiceCurrentAndFutureInfo {
    // Basically, there are 4 categories of policy: current, cancelled, future, and past.
    // We take the first of these categories with at least one item in it, and calculate total premium
    // and policy period based on *all* policies in that category.
    //
    // Note that the "display status" of the policy is irrelevant: we *only* consider the effective date,
    // expire date, and isInForce field of the period. This means that the "canceled" category
    // includes, not all canceled policies, but rather the set of policies that are within their
    // policy period and yet are not in effect. (One could term these "current canceled policies.")

    const futurePolicies: AccountSummaryPeriod[] = [];
    const inForcePolicies: AccountSummaryPeriod[] = [];
    const pastPolicies: AccountSummaryPeriod[] = [];
    const cancelledPolicies: AccountSummaryPeriod[] = [];
    let newestPastExpiryDate: moment.Moment;
    const now = moment();

    accountSummary.account.policyPeriods
      .sort((a, b) => {
        if (moment(a.expireDate).isBefore(b.expireDate)) {
          return -1;
        }
        if (moment(a.expireDate).isAfter(b.expireDate)) {
          return 1;
        }
        return 0;
      })
      .forEach((period) => {
        if (period.isInForce) {
          inForcePolicies.push(period);
        } else if (
          !period.isInForce &&
          moment(period.effectiveDate).isBefore(now) &&
          moment(period.expireDate).isAfter(now)
        ) {
          cancelledPolicies.push(period);
        } else if (moment(period.effectiveDate).isAfter(now)) {
          futurePolicies.push(period);
        } else {
          if (!newestPastExpiryDate) {
            newestPastExpiryDate = moment(period.expireDate);
            pastPolicies.push(period);
          } else if (moment(period.expireDate).isSame(newestPastExpiryDate)) {
            pastPolicies.push(period);
          }
        }
      });

    if (inForcePolicies.length) {
      return {
        relevantPolicy: this.renderRelevantPolicyInfo(inForcePolicies),
        futurePolicy: futurePolicies.length
          ? this.renderRelevantPolicyInfo(futurePolicies)
          : undefined,
      };
    } else if (cancelledPolicies.length) {
      return { relevantPolicy: this.renderRelevantPolicyInfo(cancelledPolicies) };
    } else if (futurePolicies.length) {
      return { relevantPolicy: this.renderRelevantPolicyInfo(futurePolicies) };
    }
    return { relevantPolicy: this.renderRelevantPolicyInfo(pastPolicies) };
  }

  private renderRelevantPolicyInfo(periods: AccountSummaryPeriod[]): InsuredCurrentPolicyInfo {
    const initial: InsuredCurrentPolicyInfo = {
      periodStart: moment().add(500, 'years'),
      periodEnd: moment().subtract(500, 'years'),
      premiums: {},
      paymentPlans: {},
      policyNumbers: {},
    };

    const output = periods.reduce((out: InsuredCurrentPolicyInfo, period: AccountSummaryPeriod) => {
      if (out.periodEnd.isSameOrBefore(moment.utc(period.expireDate))) {
        out.periodEnd = moment.utc(period.expireDate);
        out.periodStart = moment.utc(period.effectiveDate);
      }
      out.premiums[period.lineOfBusinessCode] = period.totalValue;
      out.paymentPlans[period.lineOfBusinessCode] = prettyPaymentPlan(period.paymentPlan);
      out.policyNumbers[period.lineOfBusinessCode] = period.policyNumber;
      return out;
    }, initial);
    return output;
  }

  downloadScheduleOfInvoices() {
    const { invoicePid, invoiceToken } = this.route.snapshot.queryParams;
    this.isLoadingScheduleOfInvoices = true;
    this.amplitudeService.track({
      eventName: 'schedule_download_attempt_billing_card',
      detail: 'insured_invoice_list',
      useLegacyEventName: true,
    });

    const url = getAttuneBopScheduleOfInvoicesUrl(invoicePid, invoiceToken, true);
    const fileName = getScheduleOfInvoicesFileName(invoicePid);
    this.documentService.getDocument(url, fileName, 'pdf').subscribe({
      next: () => {
        this.amplitudeService.track({
          eventName: 'schedule_download_success_billing_card',
          detail: 'insured_invoice_list',
          useLegacyEventName: true,
        });
      },
      error: () => {
        this.informService.infoToast(
          'Could not download the PDF of this invoice schedule. Please contact our Customer Care Team.'
        );
        this.amplitudeService.track({
          eventName: 'schedule_download_error_billing_card',
          detail: 'insured_invoice_list',
          useLegacyEventName: true,
        });
        this.isLoadingScheduleOfInvoices = false;
      },
      complete: () => {
        this.isLoadingScheduleOfInvoices = false;
      },
    });
  }

  displayLoadingState(id: string): boolean {
    return this.currentId === id;
  }

  downloadReceipt(id: string, token: string) {
    this.currentId = id;
    this.amplitudeService.track({
      eventName: 'receipt_download_attempt',
      detail: `/bop/invoice/${id}`,
      useLegacyEventName: true,
    });

    const url = getAttuneBopInvoiceReceiptUrl(id, token);
    const fileName = getInvoiceReceiptFileName(id);
    this.documentService.getDocument(url, fileName, 'pdf').subscribe({
      next: () => {
        this.amplitudeService.track({
          eventName: 'insured_invoice_receipt_download_success',
          detail: `/bop/invoice/${id}`,
          useLegacyEventName: true,
        });
      },
      error: () => {
        this.amplitudeService.track({
          eventName: 'insured_invoice_receipt_download_error',
          detail: `/bop/invoice/${id}`,
          useLegacyEventName: true,
        });
        this.informService.infoToast(
          'Could not download the PDF of this receipt. Please contact our Customer Care Team.'
        );
        this.currentId = null;
      },
      complete: () => {
        this.currentId = null;
      },
    });
  }

  hasPendingReinstatement() {
    const view = this.invoiceView();
    return isLoadedView(view) && !!view.pendingReinstatementInvoice;
  }

  getVisibleInvoices() {
    const view = this.invoiceView();
    if (isLoadedView(view) && !!view.pendingReinstatementInvoice) {
      return [view.pendingReinstatementInvoice];
    }
    return this.pagedBilledItems;
  }

  setBillingPage(page: number) {
    this.billingInvoicePager = this.insuredPaginationService.getPage(
      this.billedInvoices.length,
      page
    );
    this.pagedBilledItems = this.billedInvoices.slice(
      this.billingInvoicePager.startIndex,
      this.billingInvoicePager.endIndex + 1
    );
  }

  setPlannedPage(page: number) {
    this.pager = this.insuredPaginationService.getPage(this.plannedInvoices.length, page);
    this.pagedPlannedItems = this.plannedInvoices.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  getPaginatedFirstItemNumber(index: number) {
    return index + 1;
  }

  getPaginatedLastItemNumber(index: number, pageCount: number, pageLength: number) {
    return Math.min(index + pageLength, pageCount || 0);
  }

  isEarlyPayoffEligible(): boolean {
    const view = this.invoiceView();

    if (isLoadedView(view)) {
      // pending reinstatement
      if (view.pendingReinstatementInvoice) {
        return false;
      }

      // paid off
      if (this.madeEarlyPayoff) {
        return false;

        // paid in full
      } else if (view.amountToPay === 0 && view.payoffAmount === 0) {
        return false;

        // cancelled polices
      } else if (view.isCancelled) {
        return false;

        // one-pay
      } else if (
        view.relevantPolicy.paymentPlans &&
        Object.keys(view.relevantPolicy.paymentPlans).every(
          (plan) => view.relevantPolicy.paymentPlans[plan] === '1 Payment'
        )
      ) {
        return false;
      }
    }

    return true;
  }

  isCancelled(
    unpaidInvoices: BackendListInvoice[],
    associatedPolicies: BackendInvoiceAssociatedPolicy[]
  ): boolean {
    const filteredPolicies = this.getFilteredPolicies(associatedPolicies);
    const policyStatus = this.invoiceBannerService.getPolicyStatus(filteredPolicies);

    return policyStatus === 'Canceled' || policyStatus === 'Pending Cancel';
  }

  getRemainingAnnualPremium(): number | null {
    const view = this.invoiceView();
    let finalTotal: number | null = null;

    if (isLoadedView(view) && view.relevantPolicy) {
      if (view.pendingReinstatementInvoice) {
        return view.pendingReinstatementInvoice.amountDue;
      }

      const relevantPlanned = this.plannedInvoices.filter((plannedInvoice) => {
        return (
          moment(plannedInvoice.dueDate).isSameOrAfter(view.relevantPolicy.periodStart) &&
          moment(plannedInvoice.dueDate).isBefore(view.relevantPolicy.periodEnd)
        );
      });

      let total = 0;
      // add all of amountDue from billedInvoices, and any relevantPlanned invoices
      total =
        this.billedInvoices.reduce((acc: number, i: BackendListInvoice) => acc + i.amountDue, 0) +
        relevantPlanned.reduce((acc: number, i: BackendListInvoice) => acc + i.amountDue, 0);

      // floating point errors may result in very small deviations from zero, erase those
      if (Math.abs(total) < 0.01) {
        total = 0;
      }

      // never return negative remaining annual premium
      if (total >= 0) {
        finalTotal = total;
      } else {
        this.sentryService.notify(
          'Remaining annual premium was calculated with a negative amount',
          {
            severity: 'error',
            metaData: {
              billedInvoices: this.billedInvoices,
              relevantPolicy: view.relevantPolicy,
            },
          }
        );
      }
    }
    return finalTotal;
  }
}
