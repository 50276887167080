<div *ngIf="isLoadingInvoice" class="invoice-wrapper">
  <section class="invoice-header-section">
    <div class="invoice-header-container">
      <app-logo-with-text></app-logo-with-text>
    </div>
  </section>

  <app-invoices-metadata [isLoading]="isLoadingMetadata"></app-invoices-metadata>

  <div class="invoice-container">
    <div class="invoice-header">
      <div class="heading-info">
        <h1 class="invoice-header invoice-header__loading-placeholder"></h1>
        <p class="invoice-subhead invoice-subhead__loading-placeholder"></p>
        <p class="invoice-subhead__loading-placeholder"></p>
      </div>
      <div class="invoice-metadata">
        <div><span>Invoice No:</span><span class="invoice-loading-placeholder"></span></div>
        <div><span>Account No:</span><span class="invoice-loading-placeholder"></span></div>
        <div><span>Account name:</span><span class="invoice-loading-placeholder"></span></div>
        <div><span>Broker:</span><span class="invoice-loading-placeholder"></span></div>
        <div><span>Due On:</span><span class="invoice-loading-placeholder"></span></div>
      </div>
    </div>
    <table class="invoice-details-table">
      <thead>
        <tr>
          <th scope="col"><strong>Line of business</strong></th>
          <th scope="col"><strong>Policy number</strong></th>
          <th scope="col"><strong>Charge type</strong></th>
          <th scope="col" class="td__right"><strong>Amount</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="invoice-loading-placeholder"></div>
          </td>
          <td>
            <div class="invoice-loading-placeholder"></div>
          </td>
          <td>
            <div class="invoice-loading-placeholder"></div>
          </td>
          <td class="td__right">
            <div class="invoice-loading-placeholder"></div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="invoice-loading-placeholder"></div>
          </td>
          <td>
            <div class="invoice-loading-placeholder"></div>
          </td>
          <td>
            <div class="invoice-loading-placeholder"></div>
          </td>
          <td class="td__right">
            <div class="invoice-loading-placeholder"></div>
          </td>
        </tr>
        <tr class="invoice-total">
          <td colspan="3">Invoice total</td>
          <td class="td__right td__last"><div class="invoice-loading-placeholder"></div></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-container *ngIf="!isLoadingInvoice">
  <div *ngIf="loadedInvoice" class="invoice-wrapper">
    <section class="invoice-header-section">
      <div class="invoice-header-container">
        <app-logo-with-text></app-logo-with-text>
        <div class="print-hidden">
          <span *ngIf="hasAutopay()" class="invoice-autopay-info">
            <span>Your autopay payment method</span>
            <img
              class="invoice-autopay-icon"
              src="/assets/img/credit_card_icon.svg"
              alt="Credit card"
              *ngIf="hasCreditCardPayment()"
            />
            <img
              class="invoice-autopay-icon"
              src="/assets/img/ach_icon.svg"
              alt="ACH transfer"
              *ngIf="!hasCreditCardPayment()"
            />
            <span class="js-autopay-method-text">{{ getPaymentMethodText() }}</span>
            <button
              class="
                button button__transparent button__no-margin
                invoice-top-secondary-button
                js-autopay-edit
              "
              (click)="openStripeUpdatePaymentModal()"
            >
              Edit
            </button>
          </span>
          <ng-container *ngIf="autopayDetails && !hasAutopay()">
            <button
              class="
                button button__transparent button__no-margin
                invoice-top-secondary-button
                js-set-up-autopay
              "
              (click)="openAutopaySignupModal()"
            >
              Set up Autopay
            </button>
          </ng-container>
          <ng-container
            *ngIf="hasAutopay() && hasImminentPaymentDue() && !hasCompletedPaymentSubmission()"
          >
            <button
              id="stripeButton"
              class="button button__primary button__no-margin js-make-payment"
              type="submit"
              (click)="openStripePaymentModal()"
            >
              Make payment
            </button>
          </ng-container>
          <ng-container
            *ngIf="!hasAutopay() && getInvoiceAmount() > 0 && !hasCompletedPaymentSubmission()"
          >
            <button
              id="stripeButton"
              class="button button__primary button__no-margin js-make-payment"
              type="submit"
              (click)="openStripePaymentModal()"
            >
              Make payment
            </button>
          </ng-container>
        </div>
      </div>
    </section>

    <section
      class="invoice-dialog-section print-hidden"
      *ngIf="isBannerShowing() && showInvoiceBanners"
    >
      <div *ngIf="banners.overDueInvoice">
        <p
          class="
            dialog-text dialog-text__warning dialog-text__full-width dialog-text__no-margin
            js-overdue-dialog
          "
        >
          You have one or more overdue invoice(s), if payment is not received by
          <strong> {{ formatDateLong(gracePeriodAfterEarliestDueDate) }}</strong
          >, a notice of cancellation will be mailed.
        </p>
      </div>

      <div *ngIf="banners.uwPendingCancelInvoice">
        <p
          class="
            dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
            js-uw-cancel-outstanding-invoice-dialog
          "
        >
          Your policy is pending cancellation by the carrier effective
          <strong>{{ formatDateLong(cancellationDate) }}</strong
          >. Once this happens, you will no longer have coverage. Please pay outstanding charges. To
          learn more, please contact us
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a>
        </p>
      </div>

      <div *ngIf="banners.otherCancelInvoice">
        <p
          class="
            dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
            js-uw-cancel-outstanding-invoice-dialog
          "
        >
          Your policy is pending cancellation effective
          <strong>{{ formatDateLong(cancellationDate) }}</strong
          >. Once this happens, you will no longer have coverage. Please pay outstanding charges. To
          learn more, please contact us
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a>
        </p>
      </div>

      <div *ngIf="banners.nonPayOutstandingPendingCancellation">
        <p
          class="
            dialog-text dialog-text__error dialog-text__full-width dialog-text__no-margin
            js-uw-cancel-outstanding-invoice-dialog
          "
        >
          Your policy is pending cancellation. If you do not pay the minimum due before
          <strong>{{ formatDateLong(cancellationDate) }}, </strong>the policy will cancel. Once this
          happens, you will no longer have coverage.
        </p>
      </div>

      <div *ngIf="banners.nonPayCancelNoOutstandingInvoice">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-nopay-no-outstanding-canceled-invoice
          "
        >
          You policy was canceled effective
          <strong> {{ formatDateLong(cancellationDate) }}.</strong>
        </p>
      </div>

      <div *ngIf="banners.uwOutstandingCanceledInvoice">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-uw-outstanding-canceled-dialog
          "
        >
          You policy was canceled by the carrier effective
          <strong> {{ formatDateLong(cancellationDate) }}</strong
          >. Please pay outstanding charges for coverage provided through the cancellation effective
          date. Making payment will not reinstate coverage. To learn more, please contact your
          broker.
        </p>
      </div>

      <div *ngIf="banners.noOutstandingCanceledUWInvoice">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-uw-no-outstanding-canceled-dialog
          "
        >
          Your policy was canceled by the carrier effective
          <strong> {{ formatDateLong(cancellationDate) }}.</strong>
          To learn more, please contact your broker.
        </p>
      </div>
      <div *ngIf="banners.nonPayCanceledOutstandingInvoice">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-canceled-outstanding-invoice-dialog
          "
        >
          Your policy was canceled effective <strong> {{ formatDateLong(cancellationDate) }}</strong
          >. Please pay outstanding charges for coverage provided through the cancellation effective
          date. Making payment will not reinstate coverage. Click
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a> for information on
          how to request a review for reinstatement.
        </p>
      </div>
      <div *ngIf="banners.outstandingButNotDeliquentActiveInvoice">
        <p
          class="
            dialog-text dialog-text__neutral dialog-text__full-width dialog-text__no-margin
            js-outstanding-no-deliquent-dialog
          "
        >
          You are in good standing but have outstanding charges. We strongly recommend paying before
          becoming delinquent.
        </p>
      </div>
    </section>

    <ng-container *ngIf="!isPendingReinstatement">
      <app-invoices-metadata
        [accountSummary]="accountSummary"
        [invoice]="loadedInvoice"
        [isLoading]="isLoadingMetadata"
      >
      </app-invoices-metadata>
    </ng-container>

    <div class="invoice-container">
      <div class="invoice-header">
        <div class="heading-info">
          <h1 class="invoice-header">Your {{ getInvoiceDueDate('MMMM') }} Invoice</h1>
          <div *ngIf="isLoadingBillDate">
            <p class="invoice-subhead__loading-placeholder"></p>
            <p class="invoice-subhead__loading-placeholder"></p>
          </div>
          <div class="invoice-subhead" *ngIf="!isLoadingBillDate">
            <div *ngIf="invoiceStatus === 'paid'">
              <ng-container *ngIf="hasAutopay()">
                <ng-container *ngIf="isInvoiceEarlyPayOff">
                  This invoice was scheduled to be billed on&nbsp;
                  <strong>{{ billDate }}</strong
                  >, but was paid off early. It was paid on&nbsp;<strong
                    >{{ formatDateLong(dateOfPayment) }}.
                  </strong>
                </ng-container>
                <ng-container *ngIf="!isInvoiceEarlyPayOff">
                  This invoice has been paid. You received it on&nbsp;<strong
                    >{{ billDate }}.</strong
                  >
                  It was paid on&nbsp;<strong>{{ formatDateLong(dateOfPayment) }} </strong> using
                  automatic payment.
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!hasAutopay()">
                This invoice has been paid. You received it on&nbsp;<strong>{{ billDate }}.</strong>
                It was paid on&nbsp;<strong>{{ formatDateLong(dateOfPayment) }}.</strong>
              </ng-container>
            </div>

            <div *ngIf="invoiceStatus === 'billed'">
              <ng-container *ngIf="hasAutopay()">
                You received a new invoice on&nbsp;<strong>{{ billDate }}</strong
                >. It will be drafted using automatic payments on&nbsp;<strong
                  >{{ invoiceDraftDay }}.</strong
                >
              </ng-container>
              <ng-container *ngIf="!hasAutopay()">
                You received a new invoice on&nbsp;<strong>{{ billDate }}</strong
                >. It is due on&nbsp;<strong>{{ getInvoiceDueDate('dddd, MMMM D, YYYY') }}.</strong>
                Please make payment via the link above.
              </ng-container>
            </div>

            <div *ngIf="invoiceStatus === 'due'">
              This invoice is overdue. You received it on <strong>{{ billDate }}</strong
              >. It was due on&nbsp;<strong>{{ getInvoiceDueDate('dddd, MMMM D, YYYY') }}.</strong>
              Please make payment via the link above.
            </div>
          </div>
        </div>
        <div class="invoice-metadata">
          <div>
            <span>Invoice No:</span><span class="gray-text">{{ loadedInvoice.invoiceNumber }}</span>
          </div>
          <div>
            <span>Account No:</span><span class="gray-text">{{ loadedInvoice.accountNumber }}</span>
          </div>
          <div>
            <span>Account name:</span><span class="gray-text">{{ loadedInvoice.accountName }}</span>
          </div>
          <div>
            <span>Broker:</span><span class="gray-text">{{ loadedInvoice.agentName }}</span>
          </div>
          <div>
            <span>Due On:</span>
            <span class="gray-text">{{ getInvoiceDueDate('MM/DD/YYYY') }}</span>
          </div>
        </div>
      </div>
      <table class="invoice-details-table">
        <thead>
          <tr>
            <th scope="col"><strong>Line of business</strong></th>
            <th scope="col"><strong>Policy number</strong></th>
            <th scope="col"><strong>Charge type</strong></th>
            <th scope="col" class="td__right"><strong>Amount</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let charge of loadedInvoice.charges; let i = index"
            [ngClass]="{
              'last-item-row': i === loadedInvoice.charges.length - 1
            }"
          >
            <td
              data-column-name="Line of Business"
              [class.last-tr__padding]="i === loadedInvoice.charges.length - 1"
            >
              {{ charge.displayLob }}
            </td>
            <td data-column-name="Policy Number">{{ charge.policyNumber }}</td>
            <td data-column-name="Charge Type">{{ charge.chargeType }}</td>
            <td data-column-name="Amount" class="td__right">{{ charge.amount | currency }}</td>
          </tr>
          <tr class="invoice-total">
            <td colspan="3">Invoice total</td>
            <td class="td__right td__last js-invoice-amount-due">
              {{ getAmountPaid() | currency }}
            </td>
          </tr>
          <tr class="invoice-total outstanding-charges" *ngIf="showOutstandingBox()">
            <td colspan="3">Outstanding charges</td>
            <td class="td__right td__last">{{ getAmountToPay() | currency }}</td>
          </tr>
        </tbody>
      </table>

      <ng-container *ngIf="!isPaymentVerified && dateOfPayment">
        <div class="invoice-payment-pending-section print-hidden">
          <h3>Payment Processing</h3>
          <p>
            Thank you for making a payment! Your payment is currently processing.
            <br />
            Please check back in 3-5 business days for your receipt.
          </p>
        </div>
        <div class="print-only">
          <p class="invoice-print-receipt-text">
            Payment Processing. Please check back in 3-5 business days for your payment receipt.
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="isPaymentVerified">
        <div class="invoice-payment-receipt-section print-hidden">
          <h3>Payment Receipt</h3>
          <p>
            Your payment was successfully completed on {{ getFormattedPaymentDate(dateOfPayment) }}.
          </p>
          <a
            (click)="downloadReceipt()"
            (keyup.enter)="downloadReceipt()"
            *ngIf="!isReceiptDownloadRequestInProgress"
            >Click here to download your payment receipt.</a
          >
          <div *ngIf="isReceiptDownloadRequestInProgress">
            <span class="receipt-loading-text">Please wait...</span>
            <app-loading-spinner
              class="document-download-icon receipt-loading-spinner"
            ></app-loading-spinner>
          </div>
        </div>
        <div class="print-only">
          <p class="invoice-print-receipt-text">
            Please see the payment reciept for proof of payment.
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="allChargesVoided()">
        <div
          class="
            js-invoice-voided
            dialog-text dialog-text__neutral dialog-text__block dialog-text__margin-top
            print-hidden
          "
        >
          This invoice has no outstanding charges - most likely, it has been voided.
        </div>
      </ng-container>

      <ng-container
        *ngIf="paymentSuccessMessage && !showSecondPayoffPaymentMessage && isPaymentVerified"
      >
        <p
          class="
            dialog-text dialog-text__success dialog-text__block dialog-text__margin-top
            print-hidden
          "
        >
          <span class="success-check icon-check"></span> {{ paymentSuccessMessage }}
        </p>
      </ng-container>
    </div>

    <!-- TODO: Clean up conditionals here when we change up autopay -->
    <div
      class="invoice-payment-section print-hidden"
      *ngIf="
        !paymentSuccessMessage &&
        !isPendingReinstatement &&
        (showOverdueSection() || showOutstandingBox())
      "
      [ngClass]="{ 'invoice-payment-section__margin': showOverdueSection() }"
    >
      <div
        class="nav-button-group"
        [ngClass]="{
          'stripe-section-wrapper': !paymentSuccessMessage,
          'stripe-section-wrapper__margin': showOverdueSection()
        }"
      >
        <div *ngIf="showOverdueSection()" class="invoice-info-card-section">
          <img class="invoice-info-icon" src="/assets/img/overdue.png" alt="" />
          <div *ngIf="showOverdueBox()">
            <h3 class="invoice-overdue-header">This invoice is overdue</h3>
            <p class="gray-text">We strongly recommend paying this invoice promptly.</p>
          </div>
          <div *ngIf="showOutstandingBox()">
            <h3 class="invoice-overdue-header">You have an overdue invoice</h3>
            <p class="gray-text info-text" *ngIf="!getOverdueInvoice()">
              We strongly recommend paying either the outstanding charges or the overdue invoice.
            </p>
            <p class="gray-text info-text" *ngIf="getOverdueInvoice()">
              We strongly recommend paying either the outstanding charges or the overdue invoice
              which you can view
              <a
                target="_blank"
                [routerLink]="['/bop/invoice/', getOverdueInvoice().id]"
                [queryParams]="{ token: getOverdueInvoice().transactionId }"
                >here</a
              >.
            </p>
          </div>
        </div>

        <button
          *ngIf="showOverdueBox()"
          id="stripeButton"
          class="button button__primary button__no-margin"
          type="submit"
          (click)="openStripePaymentModal()"
        >
          Pay invoice
        </button>

        <button
          *ngIf="showOutstandingBox()"
          id="stripeButton"
          class="button button__primary button__no-margin"
          type="submit"
          (click)="openStripePaymentModal()"
        >
          Pay outstanding charges
        </button>
      </div>
    </div>

    <div
      *ngIf="showPayoffSection()"
      class="invoice-payment-section invoice-payment-section__margin print-hidden"
    >
      <div
        class="
          nav-button-group__centered
          nav-button-group
          stripe-section-wrapper stripe-section-wrapper__margin
        "
      >
        <div class="invoice-info-card-section">
          <img class="invoice-info-icon" src="/assets/img/early_payoff.png" alt="" />
          <div>
            <h3>Early payoff</h3>
            <p class="gray-text info-text">
              If you would like to payoff the remaining balance of your account early, you can make
              a payment of {{ getPayoffAmount() | currency }} now
            </p>
          </div>
        </div>
        <button
          id="payoffButton"
          class="button button__secondary"
          type="submit"
          (click)="openStripePayoffPaymentModal()"
        >
          Pay in full
        </button>
      </div>
    </div>

    <div
      *ngIf="isDeferred()"
      class="invoice-payment-section invoice-payment-section__margin print-hidden"
    >
      <app-modal
        modalType="drawer"
        [open]="showDeferredModal"
        (handleModalClose)="toggleDeferredModal()"
      >
        <h3>Deferment Invoice</h3>
        <p>
          You received this invoice because you submitted a request for payment deferment as part of
          New York State's Executive Order 202.13. Please note, while payment for this deferred
          premium amount can be made over the next 12 months, you will continue to receive
          installment invoices for the remainder of your policy term premium if you're enrolled in a
          payment plan. If you're on a payment plan and receive invoices for future installments,
          you need to pay those by their due dates, or we will cancel your policy.
        </p>
        <small class="gray-text">
          Call us at <a href="tel:8885304650">888-530-4650</a> for questions about your deferment
          invoice.
        </small>
      </app-modal>

      <ng-container *ngIf="!showDeferredModal">
        <a
          (click)="toggleDeferredModal()"
          (keyup.enter)="toggleDeferredModal()"
          class="button button__transparent"
          class="invoice-collapsed-modal"
          >Show</a
        >
        <h3>Deferment Invoice</h3>
      </ng-container>
    </div>

    <div
      *ngIf="showSecondPayoffPaymentMessage"
      class="invoice-payment-section invoice-payment-section__margin print-hidden"
    >
      <ng-container>
        <div class="invoice-info-card-section">
          <img class="invoice-info-icon" src="/assets/img/early_payoff.png" alt="" />
          <div>
            <h3>Thank you for your payment</h3>
            <p>
              Congrats, your payment was successfully completed and your policy is all paid off! For
              a receipt of your latest payment, please reach out to our Customer Care Team
              <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">here</a>.
            </p>
            <p class="gray-text">
              Note: This thank you message will disappear after you navigate away or refresh the
              page.
            </p>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="invoice-account-details print-only">
      <h3>Account Details</h3>
      <span *ngIf="hasAutopay()" class="invoice-autopay-info">
        <span>Your autopay payment method is </span>
        <span class="js-autopay-method-text">{{ getPaymentMethodText() }}</span>
      </span>
    </div>

    <app-modal
      [open]="showStripePaymentModal"
      (handleModalClose)="closeStripePaymentModal()"
      modalClass="app-modal-content__payment"
    >
      <div class="invoice-payment-modal">
        <app-invoices-payment-form
          (formSubmit)="handlePaymentFormSubmit()"
          (closePaymentModal)="closeStripePaymentModal()"
          [amountToPay]="getAmountToPay()"
          [includeCreditCardFee]="isCardProcessingFeeEligible()"
          [onlyShowAchOption]="onlyShowACHOption()"
          [paymentUpdateSuccessful]="paymentUpdateSuccessful"
          [isProcessing]="isProcessing"
          [paymentSuccessMessage]="paymentSuccessMessage"
          [isUpdatingPayment]="isUpdatingPayment"
          [serverError]="serverError"
          [isInitialAutopay]="isInitialAutopay"
          [currentAutopayIsCard]="hasCreditCardPayment()"
          [currentAutopayText]="getPaymentMethodText()"
          [hasPaymentDue]="hasAutopayPaymentDue()"
          [creditCardFee]="getCreditCardFee()"
          [creditCardFeePercentage]="getCreditCardFeePercentage()"
        >
        </app-invoices-payment-form>
      </div>
    </app-modal>

    <app-modal
      [open]="showUnenrollAutopayModal"
      (handleModalClose)="closeUnenrollAutopayModal()"
      modalClass="app-modal-content__payment app-modal-content__autopay"
    >
      <div class="unenroll-autopay-modal">
        <h1>Unenroll in Autopay?</h1>
        <p>
          Payments will no longer be drafted automatically. Please note you will be responsible for
          manual payments for the remaining invoices of this policy.
        </p>

        <p
          class="dialog-text dialog-text__error dialog-text__full-width print-hidden"
          *ngIf="unenrollErrorMessage"
        >
          {{ unenrollErrorMessage }}
        </p>

        <button
          (click)="unenrollThroughModal()"
          data-amplitude-input-name="unenroll_autopay"
          class="button button__primary button__full-width js-confirm-unenroll"
          [disabled]="isProcessingUnenroll"
        >
          <ng-container *ngIf="!isProcessingUnenroll"> Yes, unenroll me </ng-container>

          <ng-container *ngIf="isProcessingUnenroll">
            <div class="button-loader">
              <svg class="circle-spinner">
                <circle
                  cx="11"
                  cy="11"
                  r="9"
                  stroke-width="2"
                  stroke-dasharray="0,1000"
                  d="M5 40 l215 0"
                  fill="transparent"
                />
              </svg>
            </div>
            Processing unenrollment...
          </ng-container>
        </button>

        <button
          (click)="closeUnenrollAutopayModal()"
          data-amplitude-input-name="cancel_unenroll_autopay"
          class="button button__secondary button__full-width"
          [disabled]="isProcessingUnenroll"
        >
          No, remain enrolled
        </button>
      </div>
    </app-modal>

    <div class="invoice-button-container print-hidden">
      <ng-container *ngIf="!allChargesVoided()">
        <a
          class="button invoice-back-button"
          target="_blank"
          [routerLink]="['/insured/', loadedInvoice.accountNumber]"
          [queryParams]="{ invoicePid: invoiceId, invoiceToken: loadedInvoice.eTransID }"
        >
          <span class="icon-arrow-left button-icon"></span>
          View all invoices
        </a>
      </ng-container>
      <button
        id="print-button"
        class="button invoice-print-button"
        (click)="printPage()"
        data-amplitude-input-name="invoice_print"
      >
        <img
          class="invoice-print-icon button-icon"
          src="/assets/img/printer.svg"
          alt="printer icon"
        />
        Print this invoice
      </button>
      <a
        class="button billing-guide-button"
        data-amplitude-input-name="document-download-insured-invoice-policy"
        href="{{ insuredBillingGuide }}"
        target="_blank"
      >
        <span class="document-download-icon icon-download billing-guide-icon-download"></span>
        Insured Billing Guide
      </a>
    </div>
  </div>
</ng-container>

<!-- failed to load invoices -->
<div *ngIf="!loadedInvoice" class="invoice-wrapper">
  <div class="invoice-container">
    <img class="invoice-logo" alt="Attune Logo" src="/assets/img/light_horizontal_dark.svg" />
    <div class="invoice-header">
      <div class="heading-info">
        <h1>Your Invoice</h1>
        <p class="invoice-subhead">
          You have received a new invoice due on&nbsp;<strong>--------</strong>.
          <span class="print-hidden">Please make your payment via the link below.</span>
        </p>
      </div>
      <div class="invoice-metadata">
        <div><span>Invoice No:</span><span class="gray-text">--------</span></div>
        <div><span>Account No:</span><span class="gray-text">--------</span></div>
        <div><span>Account name:</span><span class="gray-text">--------</span></div>
        <div><span>Broker:</span><span class="gray-text">--------</span></div>
        <div><span>Due On:</span><span class="gray-text">--------</span></div>
      </div>
    </div>
    <div class="invoice-status-message">
      <p
        class="
          dialog-text dialog-text__error dialog-text__full-width dialog-text__margin-top
          print-hidden
        "
      >
        We’re sorry, this invoice failed to load. Please double check the URL. If the problem
        persists, please reach out to your agent or Attune’s customer care.
      </p>
    </div>
  </div>
</div>
<app-footer> </app-footer>
