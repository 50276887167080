import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_V4_BASE } from 'app/constants';
import { SentryService } from 'app/core/services/sentry.service';
import { Observable, of as observableOf, catchError } from 'rxjs';
import { PreferenceLevel } from './preference.service';

export type BookInfo = Record<
  Partial<PreferenceLevel>,
  {
    premium: number;
    numPolicies: number;
  }
>;

export interface BookMixConfig {
  calculationStartDate?: string; // US_DATE_MASK
  targetPercentage: number; // 0 - 100
}

export interface BookMix {
  producerCodeBookInfo: BookInfo;
  producerCodeBookConfig: BookMixConfig;
}

export interface BookInfoResponse {
  producerCode: string;
  wc: BookMix | null;
  bop: BookMix | null;
}

@Injectable()
export class BookMixService {
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  getBookMixInformation(producerCode: string): Observable<BookInfoResponse | null> {
    const V4_BOOK_MIX_API_URL = `${API_V4_BASE}/user/${producerCode}/book`;
    return this.http.get<BookInfoResponse>(`${V4_BOOK_MIX_API_URL}`).pipe(
      catchError((error: HttpErrorResponse) => {
        // 404s are expected if the broker has no binds since book mix calculation start date.
        if (error.status !== 404) {
          this.sentryService.notify('Failed to retrieve bookmix information', {
            severity: 'error',
            metaData: {
              producerCode,
              underlyingErrorMessage: error && error.message,
              underlyingError: error,
            },
          });
        }

        return observableOf(null);
      })
    );
  }
}
