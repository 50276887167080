<app-left-navigation></app-left-navigation>
<div class="subpage-sidebar">
  <div class="subpage-sidebar-header">
    <h3 id="support-heading">Support</h3>
  </div>
  <nav class="subpage-sidebar-main" aria-labelledby="support-heading">
    <ul>
      <li>
        <a
          class="subpage-sidebar-link"
          routerLinkActive="subpage-sidebar-link__selected"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/support"
        >
          Help center
        </a>
      </li>
      <li>
        <a
          class="subpage-sidebar-link"
          routerLinkActive="subpage-sidebar-link__selected"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/support/enhancement-library"
        >
          Enhancement Library
          <div class="pill-tag pill-tag__new">New</div>
        </a>
      </li>
      <li>
        <a
          class="subpage-sidebar-link"
          routerLinkActive="subpage-sidebar-link__selected"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/support/news"
        >
          News
        </a>
      </li>
    </ul>
  </nav>
</div>

<div role="main" class="subpage-container subpage-container__support">
  <app-support-help-center-page
    *ngIf="showHelpCenterComponent; else router"
    [authenticated]="true"
  ></app-support-help-center-page>
  <ng-template #router>
    <router-outlet></router-outlet>
  </ng-template>
  <article class="support-right-sidebar">
    <app-faq-search></app-faq-search>
    <h2 class="h3 h3__support">Underwriting guidelines</h2>
    <ul class="link-list">
      <li *ngFor="let guideline of guidelines">
        <a
          *ngIf="!guideline.isWebPageLink"
          href="javascript:void(0)"
          (click)="downloadGuideline(guideline)"
        >
          {{ guideline.fileName }}
        </a>
        <a *ngIf="guideline.isWebPageLink" href="{{ guideline.guidelineLink }}" target="_blank">
          {{ guideline.fileName }}
        </a>
      </li>
    </ul>
    <h2 class="h3 h3__support">Other downloads</h2>
    <ul class="link-list">
      <li *ngFor="let guide of additionalGuides">
        <a
          target="_blank"
          [href]="guide.guideLink"
          [attr.data-amplitude-input-name]="guide.amplitudeInputName + '-support-page'"
        >
          {{ guide.fileName }}
        </a>
      </li>
    </ul>
    <h2 class="h3 h3__support">Contact us</h2>
    <p>
      Our Customer Care Team is here for you Monday through Friday from
      {{ customerCareHours.start }} to {{ customerCareHours.end }} {{ customerCareHours.timezone }}.
    </p>
    <p>Phone: <a href="tel:+18885304650">1-888-530-4650</a></p>
  </article>
</div>
